//v2
import React, { Component } from 'react';
import { Doctor01,Doctor02,Doctor03,Doctor04,Specialities01,Specialities02,
    Specialities03,Specialities04 } from "./img";
import { Link } from 'react-router-dom';
import axios from 'axios';
import {API_CONFIG} from '../../../Config/config-env.js';
import Loader from "../Loader";
import {getPlans,getPlansv2,getMensajes, decodeToken, getCookie}  from '../functions.js';

class HomePlans extends Component{
	
	constructor(props) {
		super(props);
	 
		this.state = {
			collapseID: "collapse1",
			formActivePanel1: 1,
 			formActivePanel1Changed: false, 
 			isExecuting:true,
 			plans:[],
 			total_plans:0,
 			showAllPlans:false,
			DocIdentidadAsesor:""
		};
	}
	
	swapFormActive = (a) => (param) => (e) => {
	  this.setState({
	    ['formActivePanel' + a]: param,
	    ['formActivePanel' + a + 'Changed']: true
	  });
	}

	handleNextPrevClick = (a) => (param) => (e) => {
	  this.setState({
	    ['formActivePanel' + a]: param,
	    ['formActivePanel' + a + 'Changed']: true
	  });
	}

	handleSubmission = () => {
	  alert('Form submitted!');
	}

	calculateAutofocus = (a) => {
	  if (this.state['formActivePanel' + a + 'Changed']) {
	    return true
	  }
	}

	componentDidMount=async()=>{	 
		
		this.setState({   isExecuting:true });
		
		let canal="WEB";
		let isAdmin=false
		try{
			var tokenAsesor = getCookie("tokenINNOVA")
			var _resp = await decodeToken (tokenAsesor)
			var userInfo = _resp.datos		
			canal =  userInfo.canal	
			isAdmin  = userInfo.rol==="Administrador" || userInfo.rol==="Consultorio"?true:false			
			this.setState({ DocIdentidadAsesor : userInfo.cedula})
		}catch(Exc){
			console.log("no token")
		}
		var respMensajes = await getMensajes()
		var plans=[]
		var total_plans=0
		if (!isAdmin){
			//SAP
			//var resp = await getPlansv2(canal)
			//Sartawai
			var resp = await getPlans(0)

			total_plans = resp.result.length
			var i=0
			for (var key in resp.result){
				if (resp.result[key].canal ===canal){
					if(resp.result[key].Prestaciones){
						if (resp.result[key].Prestaciones.length>1){
							plans[key] = resp.result[key]
							plans[key]["prioridad"] = i	
							i++
						} 
					}else{  
						resp.result[key]["prioridad"]=i
						i++ 
						plans.push(resp.result[key])
					}
				}
			}
		}
		this.setState({ isExecuting:false,isAdmin : isAdmin, plans: plans, total_plans: total_plans ,direccion    :respMensajes.direccion, ubicacion    :respMensajes.ubicacion, });
		console.log(plans)
	}
	  
    render(){
        return(            
		<>
			{this.state.isExecuting?
				<div className="main-wrapper" style={{minHeight:"50vh"}}>
					<Loader idLoading={this.state.isExecuting}/>
				</div>	
				:
				 !process.env.REACT_APP_ONLINESHOP?
				<div className="main-wrapper" style={{minHeight:"50vh"}}>
					<section className="specialities">
						<div className="container">
							<div className="section-title text-center">
								<h2>Lo sentimos</h2>
								<p style={{marginTop:50}}>Por el momento no tenemos planes habilitados para la venta online, te invitamos a comunicarte al 
							 	<a href="tel:76503333">(+591) 765 03333</a>
								</p>
							</div>
						</div>
					</section> 
				</div>
			:

				<div className="main-wrapper" style={{minHeight:"50vh"}}>
				
				<Loader idLoading={this.state.isExecuting}/>
				
				{!this.state.isAdmin ?
				<>
				 
					{this.state.showAllPlans?
						<section className="specialities">
						<div className="container">
							<div className="section-title text-center">
							<h2>Nuestros Planes</h2>
							<p>Somos especialistas en ...</p>
							</div>
							<div className="row justify-content-center"> 
							<div className="card card-table" style={{"width":"100%"}}>
								<div className="card-body">
								<div className="table-responsive">
									<table className="table table-hover table-center mb-0">
									<thead>
										<tr>
										<th>Plan</th>
										<th>Descripcion</th>
										<th>Nro de Atenciones</th> 
										<th>Costo Bs. </th>
										<th></th>
										</tr>
									</thead>
									<tbody>
										{this.state.plans.map(item => (
											<tr>
											<td> 
												<div style={{"maxWidth": "150px" }}> { item.Codigo} </div> 
											</td>
											<td>
												<div style={{"maxWidth": "350px" }}> { item.Nombre?item.Nombre: item.Descripcion} </div> 
											</td>
											<td>
												<span className="checkmark ml-2">{ (item.total?item.total: item.Prestaciones?.length || 0 )+" atenciones"}</span>
												
											</td>
											<td>
												<span className="badge badge-pill bg-success-light ml-2">
												{"Bs."+(item.costo?item.costo: item.Precio)}
												</span>
											</td>
											<td className="text-right">
												<div className="table-action"> 
													{/*
													<Link to={"/patient/plan-profile/"+item.Codigo} className="booking-doc-img" style={{"marginRight": "0.5rem"}}>
																					<button type="button" className="btn cbtn-outline submit-btn">Ver m&aacute;s</button>
																				</Link>
																				*/}
												<Link to={"/patient/checkout/plan/"+( item.Codigo ) } className="booking-doc-img ">
																					<button type="button" className="btn btn-primary submit-btn">{!this.state.DocIdentidadAsesor?"Comprar":"Vender"}</button>
																				</Link>
												</div>
											</td>
											</tr> 
										))
													}  
									</tbody>
									</table>
								</div>
								</div>
							</div>
								
							</div>
							
							<div className="section-title text-right">
								<div className="row justify-content-right"  >
									<div className="col-12 col-md-12 col-lg-12">
								<a href="#"  onClick={() => {
												this.setState({
													showAllPlans: false
												})
											}}><h4>Volver </h4></a>
									</div>
								</div>
							</div>
						</div>
						</section>
					:
						<section className="specialities">
						<div className="container">
							<div className="section-title text-center">
							<h2>Nuestros Planes</h2>
							<p>Somos especialistas en ...</p>
							</div>
							<div className="row justify-content-center">
								{this.state.plans.map(item => (
									item.prioridad<4?
								<div key={"div1"+item.Codigo} className="col-12 col-md-6 col-lg-3">
									<div className="specialities-col">
									<div className="card" >
									{/*   <a href="#" className="specialities-img">
										<img src={Specialities04} alt="" />
										</a>
									*/}
										<div className="card-body">
										<div className="specialities-content" style={{  minHeight:230}}>
											<a href="#"> <h5 style={{"minHeight": "50px" }}   >{item.Codigo}</h5></a>
											<div style={{"maxWidth": "350px" }}> { item.Nombre?item.Nombre: item.Descripcion} </div> 
											<p>{"Costo: Bs."+(item.costo?item.costo: item.Precio)} </p> 
										</div>
										
										<div className="text-right justify-content-right"> 
											<div className="row">
												{/*<div className="col-6 col-md-12 col-lg-6 mt-2">
													<Link to={"/patient/plan-profile/"+item.Codigo}>
																				<button type="button" className="btn cbtn-outline">Ver m&aacute;s</button>
																			</Link>
																		</div>*/}
																		<div className="col-12 col-md-12 col-lg-12 mt-2 ">
																			<Link to={"/patient/checkout/plan/"+(item.Codigo) }>
																				<button type="button" className="btn btn-primary">{!this.state.DocIdentidadAsesor?"Comprar":"Vender"}</button>
																			</Link> 
																		</div>
																	</div>
										</div>
										
										</div>
									</div>
									</div>
								</div>:""
								))
							}  
							</div> 
							{this.state.total_plans>4?
								<div   className="section-title text-right">
									<div className="row justify-content-right">
										<div className="col-12 col-md-12 col-lg-12">
									<a href="#"  onClick={() => {
													this.setState({
														showAllPlans: true
													})
												}}><h4>Ver m&aacute;s Planes</h4></a>
										</div>
									</div>
								</div>
							:""} 
						</div>
						</section> 
					}
				</>
				:
				<div className="card mt-5 mx-3">
					<div className=" mt-5  mx-3 mb-5 alert alert-danger alert-dismissible fade show" role="alert">
						<h4 className="alert-heading">Estimado usuario</h4>
						<p><i className="fas fa-exclamation-circle mr-2" />Usted est&aacute; como Administrador no puede realizar ventas </p>
					</div>  
				</div>
				}	
				
				
				<section className="address-section">
				<div className="container">
					<div className="row">
					<div className="col-12 col-md-6">
						<address className="m-0 d-flex align-items-center">
						<span className="d-flex align-items-center justify-content-center map-icon">
						<a target="_blank" href={this.state.ubicacion}>
							<i className="fas fa-map-marker-alt" />
						</a>	
						</span>
						<span>
							{this.state.direccion} <br /> La Paz Bolivia
						</span>
						</address>
					</div>
					<div className="col-12 col-md-6">
						<div className="social-links">
							<ul>
							<li><a target="_blank" href="https://www.facebook.com/innovasaludbolivia"><i className="fab fa-facebook-f" /></a></li>
							<li><a target="_blank" href="https://twitter.com/Innovasaludbol1"><i className="fab fa-twitter" /></a></li>
							<li><a target="_blank" href="https://www.instagram.com/innovasaludbolivia/"><i className="fab fa-instagram" /></a></li>
							<li><a target="_blank" href="https://www.linkedin.com/company/innovasalud-bolivia"><i className="fab fa-linkedin" /></a></li>
						</ul>
						</div>
					</div>
					</div>
				</div>
				</section>
				{/* /Address */}
				</div>
			}
		</>
        );
    }
}
export default HomePlans;