import React from "react";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Header from "./client/components/header.jsx";
import Footer from "./client/components/footer.jsx";
 
import LoginContainer from "./client/components/login/login.jsx";
import LogoutContainer from "./client/components/login/logout.jsx";
import LoginAdminContainer from "./client/components/login/login-asesor.jsx";
import Register from "./client/components/register/register.jsx";
import ForgotPassword from "./client/components/forgot-password";
import Home from "./client/components/home/index";
import HomeEcom from "./client/components/home-ecom/index";
import HomePlans from "./client/components/home-plans/index";
import HomeServices from "./client/components/home-services/index";
import Booking from "./client/components/patients/booking/index.jsx";
import Terms from "./client/components/pages/terms/index.jsx";
import Reservation from "./client/components/reservation/index";

import BookingSuccess from "./client/components/patients/booking-success/index.jsx";
import BookingError from "./client/components/patients/booking-error/index.jsx";
import BookingTimeOUT from "./client/components/patients/booking-timeout/index.jsx";
import Checkout from "./client/components/patients/checkout/index.jsx";
import Dashboard from "./client/components/patients/dashboard/index.jsx";
import AdminDashboard from "./client/components/patients/adminDashboard/index.jsx";
import manageUsers from "./client/components/patients/manageUsers/index.jsx";


// import Home1 from "./client/components/home/home1";
// import Home2 from "./client/components/home/home2";
// import HomeSlider1 from "./client/components/home/homeslider1";
// import HomeSlider2 from "./client/components/home/homeslider2";
 

const AppContainer = function (props) {
  if (props) {
    const url = props.location.pathname.split("/")[1];

    return (
      <Router>
        {
        url === "admin" ? (
          <div>
            
          </div>
        ) :
        (
          <div>
            {/* { url === "homeslider1" && <Route render={(props)=> <TopHeader {...props}/>} />} */}
            <Route render={(props) => <Header {...props} />} />
            <Switch>
             
              <Route path="/login" exact component={LoginContainer} />
              <Route path="/logout" exact component={LogoutContainer} />
              
              <Route path="/login-asesor" exact component={LoginAdminContainer} />
              <Route path="/login-innova" exact component={LoginAdminContainer} />
              <Route path="/register" exact component={Register} />
              <Route path="/forgot-password" exact component={ForgotPassword} />
              <Route path="(/|/home)" exact component={Home} />	
              
              <Route path="/terms" exact component={Terms} />
              
              <Route path="(/HomeEcom)" exact component={HomeEcom} />	
              <Route path="(/HomePlans)" exact component={HomePlans} />	
              <Route path="(/reservation)" exact component={Reservation} />	

              <Route path="(/doctor-dashboard)" exact component={Reservation} />	
              

              
              <Route path="(/HomeServices)" exact component={HomeServices} />	
              
              <Route path="(/salesDashboard)" exact component={AdminDashboard} />         
              <Route path="(/manageUsers)"    exact component={manageUsers} />         
               
              {/* pages */}

                
             
              <Route exact path="/patient/booking/:serviceType/:productId/:token"  component={Booking} />
              <Route exact path="/patient/booking/:serviceType/:productId"  component={Booking} />
              <Route exact path="/patient/booking/:serviceType/:serviceId/:customerCI/:planCode/:forcedKey"  component={Booking} />
              
               
              <Route path="/patient/checkout/planes" exact component={Checkout} />
              
               <Route path="/patient/checkout/:productType/:Id" component={Checkout} />
               
              <Route
                path="/patient/booking-success/:productType/:productCode"
                exact
                component={BookingSuccess}
              />

             <Route
                path="/patient/booking-success/:productType"
                exact
                component={BookingSuccess}
              />
              
              <Route
                path="/patient/booking-error/:productType"
                exact
                component={BookingError}
              />
              <Route
                path="/patient/booking-timeout/:productType"
                exact
                component={BookingTimeOUT}
              />
              
               
              <Route path="/patient/dashboard/:customerDocumentId/" exact component={Dashboard} /> 
               
             
                
             
            </Switch>
            <Route render={(props) => <Footer {...props} />} />
          </div>
        )}
      </Router>
    );
  }
  return null;
};

export default AppContainer;
