import React from "react";

import {
  toast,
  ToastContainer,
  MDBFormInline,
  MDBAlert,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBStepper,
  MDBStep,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
} from "mdbreact";

const DialogBenef = (props) => {
  return (
    <>
      {props.open ? (
        <>
          <div
            className="modal-backdrop fade show"
            onClick={props.toggle ? props.toggle : () => console.log("n")}
          ></div>
          <div
            className="modal fade top show"
            style={{ display: "block", overflowY: "auto" }}
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div
              role="document"
              className="modal-dialog modal-lg modal-dialog-centered"
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">
                      {" "}
                      <i className={props.icon} /> {props.title}
                    </h4>
                    {props.toggle ? (
                      <button
                        type="button"
                        id={"1"}
                        onClick={props.toggle}
                        className="close"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">x</span>
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group card-label">
                          <label>Parentesco </label>
                          <select
                            defaultValue={props.benef_pais}
                            style={{ padding: "0px 0px", width: "100%" }}
                            id="benef_parentesco"
                            name="benef_parentesco"
                            onChange={props.changeHandler_B}
                            className={
                              "form-control " +
                              (props.validation_B[6].invalid
                                ? "is-invalid"
                                : "")
                            }
                          >
                            {props.parentescos.map((item) => (
                              <option
                                selected={
                                  item.value == props.benef_parentesco
                                    ? true
                                    : false
                                }
                                value={item.value}
                              >
                                {item.text}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12"></div>
                    </div>

                    <div className="row">
                      <div
                        className="col-md-6 col-sm-12"
                        style={{ display: "flex" }}
                      >
                        <div
                          className="form-group card-label"
                          style={{ width: "60%" }}
                        >
                          <label>Carnet de Idenditad </label>
                          <input
                            className={
                              "form-control " +
                              (props.validation_B[0].invalid
                                ? "is-invalid"
                                : "")
                            }
                            type="text"
                            name="benef_ci_cliente"
                            value={props.benef_ci_cliente}
                            onChange={props.changeHandler_B}
                          />
                        </div>

                        <div
                          className="form-group card-label ml-1"
                          style={{ width: "40%" }}
                        >
                          <label>Ext. </label>
                          <input
                            className={"form-control"}
                            type="text"
                            name="benef_extension"
                            value={props.benef_extension}
                            onChange={props.changeHandler_B}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group card-label">
                          <label>Nombres </label>
                          <input
                            className={
                              "form-control " +
                              (props.validation_B[1].invalid
                                ? "is-invalid"
                                : "")
                            }
                            type="text"
                            name="benef_user_name"
                            value={props.benef_user_name}
                            onChange={props.changeHandler_B}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group card-label">
                          <label>Apellido Paterno</label>
                          <input
                            className={
                              "form-control " +
                              (props.validation_B[2].invalid
                                ? "is-invalid"
                                : "")
                            }
                            type="text"
                            name="benef_last_name"
                            value={props.benef_last_name}
                            onChange={props.changeHandler_B}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group card-label">
                          <label>Apellido Materno</label>
                          <input
                            className={"form-control "}
                            type="text"
                            name="benef_last_name_2"
                            value={props.benef_last_name_2}
                            onChange={props.changeHandler_B}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group card-label">
                          <span className="ml-2" style={{ color: "#757575" }}>
                            Sexo del paciente
                          </span>
                          <MDBFormInline>
                            <label className="ml-2">
                              <input
                                type="radio"
                                name="benef_radioGenero"
                                checked={
                                  props.benef_radioGenero === 1 ? true : false
                                }
                                onClick={props.onClickRadioGen_B(0)}
                              />
                              <span className="checkmark ml-1">Mujer</span>
                            </label>
                            <label className="ml-2">
                              <input
                                type="radio"
                                name="benef_radioGenero"
                                checked={
                                  props.benef_radioGenero === 2 ? true : false
                                }
                                onClick={props.onClickRadioGen_B(1)}
                              />
                              <span className="checkmark ml-1">Hombre</span>
                            </label>
                          </MDBFormInline>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group card-label">
                          <span className="ml-2" style={{ color: "#757575" }}>
                            Fecha de Nacimiento
                          </span>
                          <MDBFormInline>
                            <label className="ml-2">
                              <select
                                defaultValue={props.benef_day}
                                style={{ padding: "0px 0px" }}
                                id="benef_day"
                                name="benef_day"
                                onChange={props.changeHandler_B}
                                className={
                                  "form-control " +
                                  (props.validation_B[3].invalid
                                    ? "is-invalid"
                                    : "")
                                }
                              >
                                {props.v_days_T.map((item) => (
                                  <option
                                    selected={
                                      item.value == props.benef_day
                                        ? true
                                        : false
                                    }
                                    value={item.value}
                                  >
                                    {item.text}
                                  </option>
                                ))}
                              </select>
                            </label>
                            <label className="ml-2">
                              <select
                                defaultValue={props.benef_month}
                                style={{ padding: "0px 0px" }}
                                id="benef_month"
                                name="benef_month"
                                onChange={props.changeHandler_B}
                                className={
                                  "form-control " +
                                  (props.validation_B[4].invalid
                                    ? "is-invalid"
                                    : "")
                                }
                              >
                                {props.v_months_T.map((item) => (
                                  <option
                                    selected={
                                      item.value == props.benef_month
                                        ? true
                                        : false
                                    }
                                    value={item.value}
                                  >
                                    {item.text}
                                  </option>
                                ))}
                              </select>
                            </label>
                            <label className="ml-2">
                              <select
                                defaultValue={props.benef_year}
                                style={{ padding: "0px 0px" }}
                                id="benef_year"
                                name="benef_year"
                                onChange={props.changeHandler_B}
                                className={
                                  "form-control " +
                                  (props.validation_B[5].invalid
                                    ? "is-invalid"
                                    : "")
                                }
                              >
                                {props.v_years_T.map((item) => (
                                  <option
                                    selected={
                                      item.value == props.benef_year
                                        ? true
                                        : false
                                    }
                                    value={item.value}
                                  >
                                    {item.text}
                                  </option>
                                ))}
                              </select>
                            </label>
                          </MDBFormInline>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group card-label">
                          <label>Teléfono celular</label>
                          <input
                            className={
                              "form-control "  
                            }
                            type="text"
                            name="benef_phone"
                            value={props.benef_phone}
                            onChange={props.changeHandler_B}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group card-label">
                          <label>Correo electr&oacute;nico</label>
                          <input
                            className={
                              "form-control " 
                            }
                            type="email"
                            name="benef_email"
                            value={props.benef_email}
                            onChange={props.changeHandler_B}
                          />
                        </div>
                      </div>
                    </div>
 
                    
                    <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <div className="form-group card-label">
                          <label>Direcci&oacute;n</label>
                          <input
                            className={
                              "form-control "  
                            }
                            type="text"
                            name="benef_direccion"
                            value={props.benef_direccion}
                            onChange={props.changeHandler_B}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer justify-content-center text-center">
                    <>
                      {props.buttons.map((item) => (
                        <button
                          type="submit"
                          onClick={item.buttonAction}
                          key={item.buttonId}
                          className={item.buttonClass}
                        >
                          {item.buttonName}{" "}
                        </button>
                      ))}

                      {props.cancelButton ? (
                        <button
                          type="submit"
                          onClick={
                            props.toggle ? props.toggle : console.log("nodef")
                          }
                          key={"cancel"}
                          className="btn btn-secondary btn-sm"
                        >
                          Atras{" "}
                        </button>
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default DialogBenef;
