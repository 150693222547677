import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { DashboardSidebar } from "./sidebar/sidebar.jsx";
import { Tab, Tabs } from "react-bootstrap";
import DateTimePicker from "./DateTimePicker";
import Table from "./Table";
import Loader from "../../Loader";
import Dialog from "../../Dialog";
import {
  MDBIcon,
  toast,
  ToastContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdbreact";
import {
  cerrarVenta,
  getResponseBackEndEfectivo,
  validarVenta,
  conciliarVenta,
  getOrders,
  getUsers,
  getCookie,
  enviarWspV2,
  getValoresXDominio,
  enviarMailV2,
  getMensajes,
  getEstadoReserva,
  getClienteByCIV2,
  getProductosByClienteV2,
  cancelaReserva,
  getProductosByCliente,
  getReservasByCliente,
  decodeToken,
  generateTempToken,
} from "../../functions.js";
import { API_CONFIG } from "../../../../Config/config-env.js";
import { find } from "../../functions.js";
import {
  IMG01,
  IMG02,
  IMG03,
  IMG04,
  IMG05,
  IMG06,
  IMG07,
  IMG08,
  IMG09,
  IMG10,
} from "./img";

import ReactExport from "react-export-excel-xlsx-fix";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var fecNum = Date.now();
var fecNum2 = Date.now() + 1;

var f = new Date();
var fromDate = new Date();
fromDate.setDate(f.getDate() - 3);

var mes =
  f.getUTCMonth() + 1 < 10 ? "0" + (f.getUTCMonth() + 1) : f.getUTCMonth() + 1;
var dia = f.getUTCDate() < 10 ? "0" + f.getUTCDate() : f.getUTCDate();
var date_label = f.getUTCFullYear() + "." + mes + "." + dia;

var wWidth = window.innerWidth;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      bookings: [],
      modalConfirmTRX: false,
      modalConfirm: false,
      finalResponse: [],
      token_to_delete: "",
      date_label: date_label,
      loaded: false,
      noOrdenes: false,
      customerDocumentId: 0,
      RadioReservationType: 0,
      customerName: "",
      customerId: 0,
      redirect: false,
      productId: 0,
      birthDate: null,
      bookingName: "",
      products: [],
      servicios: [],
      canales: [],
      canal: "",
      origOrders: [],
      tableData: {
        columns: [
          {
            dataIndex: "id",
            title: "Action",
            render: (text, record) => (
              <div style={{ width: 105 }}>
                {record.estado == "PAGADO" && (
                  <button
                    className="btn btn-sm btn-info"
                    id={record.id_orden}
                    name="cerrar"
                    value={record.id_orden}
                    onClick={this.onClickRegistro}
                    type="submit"
                  >
                    <i className="fas fa-lock" />
                  </button>
                )}

                {record.estado == "PAGADO" &&
                  record.conciliacion !== "CERRADO" &&
                  this.state.rol === "Administrador" && (
                    <button
                      className="btn btn-sm btn-danger ml-1"
                      id={record.id_orden}
                      name="anular"
                      value={record.id_orden}
                      onClick={this.onClickRegistro}
                      type="submit"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  )}

                {(record.estado === "ERROR" || record.estado === "PENDIENTE") &&
                  this.state.rol === "Administrador" && (
                    <button
                      className="btn btn-sm btn-warning ml-1"
                      id={record.id_orden}
                      name="reintentar"
                      value={record.id_orden}
                      onClick={this.onClickRegistro}
                      type="submit"
                    >
                      <i class="fas fa-sync"></i>
                    </button>
                  )}
              </div>
            ),
          },

          {
            dataIndex: "checkid",
            title: "Seleccionar",
            render: (text, record) => (
              <div style={{ width: 33 }}>
                <div className="custom-checkbox">
                  <input
                    onClick={this.onClickRegistro}
                    name="checkItem"
                    value={record.id_orden}
                    type="checkbox"
                    checked={record.checked}
                  />
                </div>
              </div>
            ),
          },

          { dataIndex: "id_orden", title: "Id orden" },
          { dataIndex: "estado", title: "Estado Pasarela de Pago" },
          { dataIndex: "estado_sap", title: "Estado SAP" },

          {
            dataIndex: "estado_conciliacion",
            title: "Estado Conciliaci\u00F3n",
          },

          { dataIndex: "fecha_cierre", title: "Fecha Cierre" },
          { dataIndex: "fecha_conciliacion", title: "Fecha Conciliaci\u00F3n" },

          { dataIndex: "metodo", title: "Metodo Pago" },
          { dataIndex: "respuesta_pago", title: "Id Transacción" },
          { dataIndex: "fecha_solicitud_pago", title: "Fecha Solicitud Pago" },
          { dataIndex: "fecha_registro_pago", title: "Fecha Registro Pago" },
          { dataIndex: "DocIdentidad", title: "Doc. Identidad Cliente" },
          { dataIndex: "email_usuario", title: "Email Cliente" },
          { dataIndex: "CodOficina", title: "Codigo Oficina" },
          { dataIndex: "Tipo", title: "Tipo" },
          { dataIndex: "RazonSocial", title: "Razon Social" },
          { dataIndex: "Nit", title: "Nit" },
          { dataIndex: "CodServicio", title: "Codigo Servicio" },
          { dataIndex: "DescServicio", title: "Servicio" },
          { dataIndex: "MontoTotal", title: "Monto Total" },
          { dataIndex: "NumIdentificador", title: "NumIdentificador" },
          { dataIndex: "DocIdentidadAsesor", title: "Doc.Identidad Asesor" },
          { dataIndex: "Canal", title: "Canal" },
        ],
        rows: [],
      },

      tableData2: {
        columns: [
          { dataIndex: "id_orden", title: "Id orden" },
          { dataIndex: "nro_certificado", title: "Nro. Certificado" },
          {
            dataIndex: "nro_contrato",
            title: "Nro. Contrato (Agrupador De Familia)",
          },
          { dataIndex: "producto", title: "Producto" },
          { dataIndex: "prima_cobrada", title: "Prima  Cobrada" },
          { dataIndex: "moneda_prima", title: "Moneda  Prima" },
          {
            dataIndex: "fecha_inicio_de_vigencia",
            title: "Fecha Inicio De Vigencia",
          },
          { dataIndex: "nro_integrante", title: "Nro. Integrante" },
          { dataIndex: "tipo_de_documento", title: "Tipo De Documento" },
          { dataIndex: "numero_documento", title: "N\u00FAmero Documento" },
          { dataIndex: "complemento", title: "Complemento" },
          {
            dataIndex: "lugar_de_expedicion",
            title: "Lugar De Expedici\u00F3n",
          },
          { dataIndex: "ap_paterno", title: "Ap. Paterno" },
          { dataIndex: "ap_materno", title: "Ap. Materno" },
          { dataIndex: "nombres", title: "Nombres" },
          { dataIndex: "fecha_de_nacimiento", title: "Fecha De Nacimiento" },
          { dataIndex: "direccion", title: "Direcci\u00F3n" },
          { dataIndex: "nacionalidad", title: "Nacionalidad" },
          { dataIndex: "genero", title: "G\u00E9nero" },
          { dataIndex: "estado_civil", title: "Estado Civil" },
          { dataIndex: "ocupacion", title: "Ocupaci\u00F3n" },
          { dataIndex: "telefono", title: "Tel\u00E9fono" },
          { dataIndex: "canal", title: "Canal" },
        ],
        rows: [],
      },

      estados: [
        { value: "", text: "TODOS" },
        { value: "PAGADO", text: "PAGADO" },
        { value: "FACTURADO", text: "FACTURADO" },
        { value: "PENDIENTE", text: "PENDIENTE" },
        { value: "ERROR", text: "ERROR" },
      ],
      estados_conciliacion: [
        { value: "", text: "TODOS" },
        { value: "PENDIENTE", text: "PENDIENTE" },
        { value: "CERRADO", text: "CERRADO" },
        { value: "VALIDADO", text: "VALIDADO" },
        { value: "CONCILIADO", text: "CONCILIADO" },
      ],
      tipo_pago: "",
      tipos_pago: [
        { value: "", text: "TODOS" },
        { value: "QR", text: "QR" },
        { value: "Efectivo", text: "PAGO EN EFECTIVO" },
        { value: "CYBER", text: "TARJETA DE DEBITO/CREDITO" },
      ],
      estado: "",
      estado_conciliacion: "",
      desde: fromDate,
      rol: "",
      hasta: new Date(),
      servicio: "",
      productsNV: [],
      customerAge: 0,
      validityProduct: "",
      productName: "",
      modal1: false,
      repartidores: [],
      repartidor: "",
      hoy: new Date(),
      selectAll: false,
      buttonsModalCancel: [
        {
          buttonAction: this.cancelaReserva,
          buttonId: 1,
          buttonClass: "btn btn-outline-danger btn-sm",
          buttonName: "Cancelar Reserva",
        },
      ],
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  onClickRegistro = async (event) => {
    var type = event.target.name;
    var val = event.target.value;
    if (!event.target.name) {
      type = event.target.parentNode.name;
      val = event.target.parentNode.value;
    }

    if (type === "checkItem") {
      const tableData = this.state.tableData;
      let pos = find(tableData.rows, val, "id_orden");
      var _rows = tableData.rows;
      tableData["rows"] = []; //this.state.origOrders
      this.setState({ ...this.state, tableData: tableData });

      setTimeout(
        function () {
          _rows[pos]["checked"] = !_rows[pos]["checked"];
          tableData.rows = _rows;
          this.setState({ ...this.state, tableData: tableData });
        }.bind(this),
        1
      );
    } else {
      console.log("modalConfirmTRX");
      this.setState({
        ...this.state,
        modalConfirmTRX: true,
        typeAction: type,
        orderid: val,
      });
    }
  };

  setRegistro = async (event) => {
    var type = this.state.typeAction;
    var val = this.state.orderid;
    /*
	if (!event.target.name){
		type = event.target.parentNode.name
		val =  event.target.parentNode.value
	}
	*/

    //console.log("click", type, val)
    if (type === "cerrar") {
      console.log("rol", this.state.rol);
      this.setState({ ...this.state, isExecuting: true });
      var resp;
      var estado_nuevo = "";
      if (this.state.rol === "Vendedor") {
        resp = await cerrarVenta(val);
        estado_nuevo = "CERRADO";
      } else if (this.state.rol === "Supervisor") {
        resp = await validarVenta(val);
        estado_nuevo = "VALIDADO";
      } else if (this.state.rol === "Administrador") {
        resp = await conciliarVenta(val);
        estado_nuevo = "CONCILIADO";
      }
      if (resp.Estado === "E") {
        toast.success(resp.message, { closeButton: true });
        const tableData = this.state.tableData;
        let pos = find(tableData.rows, val, "id_orden");
        var _rows = tableData.rows;
        tableData["rows"] = []; //this.state.origOrders
        this.setState({ ...this.state, tableData: tableData });
        setTimeout(
          function () {
            _rows[pos]["estado_conciliacion"] = estado_nuevo;
            tableData.rows = _rows;
            this.setState({ ...this.state, tableData: tableData });
          }.bind(this),
          1
        );
      } else {
        toast.warning(resp.message, { closeButton: true });
      }
      this.setState({ ...this.state, isExecuting: false });
    } else if (type === "reintentar") {
      this.setState({ ...this.state, isExecuting: true });
      var resp = {
        Estado: "X",
        message: "Este rol no puede ejecutar esta acci\u00F3n",
      };
      var estado_nuevo = "";
      if (this.state.rol === "Administrador") {
        resp = await getResponseBackEndEfectivo(val, this.state.cedula);
        estado_nuevo = "PAGADO";
      }
      if (resp.resp === "E") {
        toast.success(resp.message, { closeButton: true });
        const tableData = this.state.tableData;
        let pos = find(tableData.rows, val, "id_orden");
        var _rows = tableData.rows;
        tableData["rows"] = []; //this.state.origOrders
        this.setState({ ...this.state, tableData: tableData });

        setTimeout(
          function () {
            _rows[pos]["estado"] = estado_nuevo;
            tableData.rows = _rows;
            this.setState({ ...this.state, tableData: tableData });
          }.bind(this),
          1
        );
      } else {
        toast.warning(resp.message, { closeButton: true });
      }
      this.setState({ ...this.state, isExecuting: false });
    }
    this.setState({ ...this.state, modalConfirmTRX: false });
  };

  onSelectAll = (event) => {
    var tableData = this.state.tableData;
    var _rows = tableData.rows;
    tableData["rows"] = [];

    this.setState({ ...this.state, tableData: tableData });

    setTimeout(
      function () {
        for (let r in _rows) {
          _rows[r]["checked"] = !this.state.selectAll;
        }
        console.log(_rows);
        tableData.rows = _rows;

        this.setState({
          ...this.state,
          tableData: tableData,
          selectAll: !this.state.selectAll,
        });
      }.bind(this),
      0
    );
  };

  changeHandler = async (event) => {
    //console.log(event.target.value, event.target.name)

    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSelect(key) {
    this.setState({ key });
  }
  onClickRadioReservationType = (nr, serviceId, key) => () => {
    this.setState({
      ...this.state,
      RadioReservationType: nr,
    });
    const bookings = this.state.bookings;
    for (var i in bookings) {
      if (bookings[i].id_servicio == serviceId) {
        bookings[i].llaves[key].serviceType = nr;
      }
    }
  };

  componentDidMount = async () => {
    this.setState({ isExecuting: true });

    let token = getCookie("tokenINNOVA");

    if (!token) {
      this.setState({ redirect: true, isExecuting: false });
    } else {
      var _resp = await decodeToken(token);
      var userInfo = _resp.datos;
      this.setState({ userInfo: userInfo });
      var rol = userInfo.rol;
      var cedula = userInfo.cedula;
      var canal = userInfo.canal;

      var resp = await getUsers(true);
      var repartidores = [];
      if (resp.users.length > 1) {
        repartidores.push({ value: "", text: "TODOS" });
      }
      resp.users.map(function (key) {
        repartidores.push({
          value: key.cedula,
          text:
            key.primer_nombre +
            " " +
            key.apellido_paterno +
            "(" +
            key.cedula +
            ")",
        });
      });
      var repartidor = repartidores[0].value;

      var canales = [];
      if (userInfo.rol == "Administrador") {
        canales.push({ value: "", text: "TODOS" });
      }

      var resp = await getValoresXDominio("canales");
      var i = 0;
      for (var key in resp.rows) {
        if (userInfo.rol == "Administrador") {
          canales.push({
            value: resp.rows[key].nombre,
            text: resp.rows[key].nombre,
          });
        } else {
          if (canal == resp.rows[key].nombre) {
            canales.push({
              value: resp.rows[key].nombre,
              text: resp.rows[key].nombre,
            });
          }
        }
      }

      var canal = canales[0].value;

      this.setState({
        ...this.state,
        loaded: true,
        canales: canales,
        canal: canal,
        rol: rol,
        cedula: cedula,
        //servicios: servicios,
        //servicio:servicio,
        repartidor: repartidor,
        repartidores: repartidores,
        isExecuting: false,
      });
    }
  };
  toggleM = (nr) => () => {
    this.setState({
      ["modal" + nr]: !this.state["modal" + nr],
    });
  };

  getOrders = async (event) => {
    this.setState({ isExecuting: true });
    let f = this.state.desde;
    let desde =
      f.getFullYear() +
      "" +
      "-" +
      (f.getMonth() + 1 < 10 ? "0" + (f.getMonth() + 1) : f.getMonth() + 1) +
      "-" +
      (f.getDate() < 10 ? "0" + f.getDate() : f.getDate());
    f = this.state.hasta;
    let hasta =
      f.getFullYear() +
      "" +
      "-" +
      (f.getMonth() + 1 < 10 ? "0" + (f.getMonth() + 1) : f.getMonth() + 1) +
      "-" +
      (f.getDate() < 10 ? "0" + f.getDate() : f.getDate());

    let token = getCookie("tokenINNOVA");

    var formData = {
      token: token,
      desde: desde,
      hasta: hasta,
      repartidor: this.state.repartidor,
      servicio: this.state.servicio,
      estado: this.state.estado,
      tipos_pago: this.state.tipos_pago,
      canal: this.state.canal,
      estado_conciliacion: this.state.estado_conciliacion,
    };

    var resp = await getOrders(formData);

    if (resp.success) {
      if (resp.orders) {
        for (var r in resp.orders) {
          let row = resp.orders[r];
          let estados = row.estado.split("___");

          row["estado"] = estados[0];
          if (row.estado === "PENDIENTE") {
            row["estado_sap"] = "PENDIENTE";
          } else {
            row["estado_sap"] = estados[1] ? estados[1] : "NO EJECUTADO";
          }
        }
      }
      const tableData = this.state.tableData;
      tableData.rows = resp.orders ? resp.orders : [];

      if (resp.contratos) {
        for (var r in resp.contratos) {
          let row = resp.contratos[r];
        }
      }
      console.log(resp.orders);
      const tableData2 = this.state.tableData2;
      tableData2.rows = resp.contratos ? resp.contratos : [];
    }

    this.setState({
      ...this.state,
      isExecuting: false,
      origOrders: resp.orders,
    });
  };

  toggleConfirm = () => {
    this.setState({
      modalConfirm: !this.state.modalConfirm,
    });
  };

  toggleNoOrdenes = () => {
    this.setState({
      noOrdenes: !this.state.noOrdenes,
    });
  };

  toggleConfirmTRX = () => {
    this.setState({
      modalConfirmTRX: !this.state.modalConfirmTRX,
    });
  };

  executeAction = async () => {
    console.log(this.state.action);
    console.log(this.state.tableData.rows);
    /*
	resp.users.map(function (key) {
		repartidores.push({ value: key.cedula, text: key.primer_nombre+" "+key.apellido_paterno+"("+key.cedula+")" })
  	 }); 
*/
    var type = this.state.action;
    var newArray = this.state.tableData.rows.filter(function (item) {
      if (item.checked) {
        if (type === "cerrar") {
          if (
            item.estado === "PAGADO" &&
            item.estado_conciliacion === "PENDIENTE"
          ) {
            return true;
          } else {
            return false;
          }
        } else if (type === "validar") {
          if (
            item.estado === "PAGADO" &&
            item.estado_conciliacion === "CERRADO"
          ) {
            return true;
          } else {
            return false;
          }
        } else if (type === "reintentar") {
          if (item.estado === "ERROR") {
            return true;
          } else {
            return false;
          }
        } else if (type === "conciliar") {
          if (
            item.estado === "PAGADO" &&
            item.estado_conciliacion === "VALIDADO"
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
      //return item.checked;
    });

    var orders = newArray.map(function (a) {
      return a.id_orden;
    });

    console.log("type", type, orders);

    var finalResponse = [];

    if (orders.length > 0) {
      this.setState({ ...this.state, isExecuting: true });
      for (let v in orders) {
        let val = orders[v];
        if (type === "cerrar" || type === "validar" || type === "conciliar") {
          console.log("rol", this.state.rol);

          var resp;
          var estado_nuevo = "";
          if (this.state.rol === "Vendedor") {
            resp = await cerrarVenta(val);
            estado_nuevo = "CERRADO";
          } else if (this.state.rol === "Supervisor") {
            resp = await validarVenta(val);
            estado_nuevo = "VALIDADO";
          } else if (this.state.rol === "Administrador") {
            resp = await conciliarVenta(val);
            estado_nuevo = "CONCILIADO";
          }
          if (resp.Estado === "E") {
            finalResponse.push("ORDEN:" + val + " PROCESADA CON EXITO");
          } else {
            finalResponse.push(
              "ORDEN:" + val + " NO PROCESADA. " + resp.message
            );
          }
        } else if (type === "reintentar") {
          var resp = {
            Estado: "X",
            message: "Este rol no puede ejecutar esta acci\u00F3n",
          };
          var estado_nuevo = "";
          if (this.state.rol === "Administrador") {
            resp = await getResponseBackEndEfectivo(val, this.state.cedula);
            estado_nuevo = "PAGADO";
          }
          if (resp.Estado === "E") {
            finalResponse.push("ORDEN:" + val + " PROCESADA CON EXITO");
          } else {
            finalResponse.push(
              "ORDEN:" + val + " NO PROCESADA. " + resp.message
            );
          }
        }
      }

      this.setState({
        finalResponse: finalResponse,
        noOrdenes: true,
      });
      console.log("finalResponse", finalResponse);
    } else {
      this.setState({
        modalConfirm: false,
        noOrdenes: true,
        finalResponse: [],
      });
    }
    this.setState({
      modalConfirm: false,
      isExecuting: false,
    });
  };

  setAction = (action) => {
    let actionLabel = "el cierre ";

    if (action === "conciliar") {
      actionLabel = "la conciliaci\u00F3n ";
    } else if (action === "validar") {
      actionLabel = "la validaci\u00F3n ";
    } else if (action === "reintentar") {
      actionLabel = "el reintento de facturaci\u00F3n ";
    }

    this.setState({
      modalConfirm: true,
      action: action,
      actionLabel: actionLabel,
      noOrdenes: false,
      finalResponse: [],
    });
  };

  render() {
    return (
      <>
        <ToastContainer
          hideProgressBar={true}
          newestOnTop={false}
          position={"bottom-right"}
          autoClose={3000}
        />
        {this.state.redirect ? (
          <Redirect to={{ pathname: "/login-asesor" }} />
        ) : (
          ""
        )}

        {this.state.noOrdenes && (
          <MDBModal
            isOpen={this.state.noOrdenes}
            centered
            toggle={this.toggleNoOrdenes}
          >
            <MDBModalBody>
              <MDBContainer>
                <MDBRow style={{ marginTop: 0 }}>
                  <MDBCol md="12">
                    <div style={{ fontSize: 16 }}>
                      {this.state.finalResponse.length == 0 ? (
                        <span>
                          {" "}
                          No hay ordenes que cumplan los requisitos para ser
                          procesadas
                        </span>
                      ) : (
                        <>
                          {this.state.finalResponse.map((item) => (
                            <p>{item}</p>
                          ))}
                        </>
                      )}
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow style={{ marginTop: 0 }}>
                  <MDBCol md="12" style={{ textAlign: "right" }}>
                    <button
                      type="button"
                      className="btn btn-outline-secondary submit-btn"
                      onClick={this.toggleNoOrdenes}
                    >
                      Cerrar{" "}
                    </button>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBModalBody>
          </MDBModal>
        )}

        {this.state.modalConfirm && (
          <MDBModal
            isOpen={this.state.modalConfirm}
            centered
            toggle={this.toggleConfirm}
          >
            <MDBModalHeader>
              <h3>Confirmaci&oacute;n de Acci&oacute;n</h3>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBContainer>
                <MDBRow style={{ marginTop: 0 }}>
                  <MDBCol md="12">
                    <div style={{ fontSize: 18 }}>
                      &iquest;Desea proceder {this.state.actionLabel} de las
                      ordenes seleccionadas?
                      <p className="text-muted">
                        (Solo se ejecutar las que tengan el estado
                        correspondiente)
                      </p>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBModalBody>
            <MDBModalFooter>
              <button
                type="button"
                className="btn btn-outline-secondary submit-btn"
                onClick={this.toggleConfirm}
              >
                Cancelar{" "}
              </button>

              <button
                type="button"
                className="btn btn-primary submit-btn"
                onClick={this.executeAction.bind(this)}
              >
                <span className="ml-2"> Confirmar </span>{" "}
              </button>
            </MDBModalFooter>
          </MDBModal>
        )}

        {this.state.modalConfirmTRX && (
          <MDBModal
            isOpen={this.state.modalConfirmTRX}
            centered
            toggle={this.toggleConfirmTRX}
          >
            <MDBModalHeader>
              <h3>Confirmaci&oacute;n de Acci&oacute;n</h3>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBContainer>
                <MDBRow style={{ marginTop: 0 }}>
                  <MDBCol md="12">
                    <div style={{ fontSize: 18 }}>
                      &iquest;Desea proceder con la orden {this.state.orderid}
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBModalBody>
            <MDBModalFooter>
              <button
                type="button"
                className="btn btn-outline-secondary submit-btn"
                onClick={this.toggleConfirmTRX}
              >
                Cancelar{" "}
              </button>
              <button
                type="button"
                className="btn btn-primary submit-btn"
                onClick={this.setRegistro.bind(this)}
              >
                <span className="ml-2"> Confirmar </span>{" "}
              </button>
            </MDBModalFooter>
          </MDBModal>
        )}

        <div>
          <div className="breadcrumb-bar">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-12 col-12">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/home">Inicio</Link>
                      </li>
                    </ol>
                  </nav>
                  <h2 className="breadcrumb-title">Reporte de Ventas</h2>
                </div>
              </div>
            </div>
          </div>
          <Loader idLoading={this.state.isExecuting} />

          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 theiaStickySidebar">
                  <div className="row">
                    <div className="col-12">
                      <div className="col-12">
                        <span className="h3-responsive ">
                          <i className="fas fa-filter" /> Criterios de Busqueda
                        </span>
                      </div>
                    </div>
                  </div>
                  <span className="or-line" />

                  <div className="col-12">
                    <div className="form-group card-label">
                      <label> Desde </label>
                      <DateTimePicker
                        date={this.state.desde ? this.state.desde : new Date()}
                        name="desde"
                        label="Desde"
                        isUpdatable={true}
                        updateDate={this.changeHandler}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group card-label">
                      <label> Hasta </label>
                      <DateTimePicker
                        date={this.state.hasta ? this.state.hasta : new Date()}
                        name="hasta"
                        label="Hasta"
                        isUpdatable={true}
                        updateDate={this.changeHandler}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group card-label">
                      <label> Canal </label>
                      <select
                        className="browser-default custom-select"
                        name="canal"
                        onChange={this.changeHandler}
                        defaultValue={this.state.canal}
                      >
                        {this.state.canales.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group card-label">
                      <label> Vendedor </label>
                      <select
                        className="browser-default custom-select"
                        name="repartidor"
                        onChange={this.changeHandler}
                        defaultValue={this.state.repartidor}
                      >
                        {this.state.repartidores.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group card-label">
                      <label> Tipo de Pago </label>
                      <select
                        className="browser-default custom-select"
                        name="tipo_pago"
                        onChange={this.changeHandler}
                        defaultValue={this.state.tipo_pago}
                      >
                        {this.state.tipos_pago.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group card-label">
                      <label> Estado </label>
                      <select
                        className="browser-default custom-select"
                        name="estado"
                        onChange={this.changeHandler}
                        defaultValue={this.state.estado}
                      >
                        {this.state.estados.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group card-label">
                      <label> Estado Conciliaci&oacute;n </label>
                      <select
                        className="browser-default custom-select"
                        name="estado_conciliacion"
                        onChange={this.changeHandler}
                        defaultValue={this.state.estado_conciliacion}
                      >
                        {this.state.estados_conciliacion.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group card-label">
                      <button
                        className="btn btn-primary btn-block"
                        name="search"
                        onClick={this.getOrders}
                        type="submit"
                      >
                        Generar Reporte
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  id="atenciones"
                  className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9"
                >
                  <div className="card">
                    <div className="card-body pt-0">
                      <div
                        id="results"
                        className="col-12 col-lg-12 text-right mb-2 mt-3"
                        style={{ padding: 0 }}
                      >
                        <button
                          style={{ position: "absolute", left: 0 }}
                          disabled={this.state.tableData.rows.length === 0}
                          onClick={this.onSelectAll}
                          type="button"
                          className="btn btn-sm btn-secondary"
                          name="set"
                        >
                          {" "}
                          <i className="fa fa-check"></i>{" "}
                          {!this.state.selectAll
                            ? "Marcar todas"
                            : "Desmarcar todas"}{" "}
                        </button>

                        {this.state.rol === "Vendedor" && (
                          <button
                            disabled={this.state.tableData.rows.length === 0}
                            onClick={() => {
                              this.setAction("cerrar");
                            }}
                            type="button"
                            className="btn btn-sm btn-info"
                            name="set"
                          >
                            {" "}
                            <i className="fa fa-lock"></i>{" "}
                            {wWidth > 700 && " Cerrar Ventas"}
                          </button>
                        )}
                        {this.state.rol === "Supervisor" && (
                          <button
                            disabled={this.state.tableData.rows.length === 0}
                            onClick={() => {
                              this.setAction("validar");
                            }}
                            type="button"
                            className="btn btn-sm btn-info"
                            name="set"
                          >
                            {" "}
                            <i className="fa fa-lock"></i>{" "}
                            {wWidth > 700 && "Validar Ventas"}
                          </button>
                        )}
                        {this.state.rol === "Administrador" && (
                          <>
                            <button
                              disabled={this.state.tableData.rows.length === 0}
                              onClick={() => {
                                this.setAction("conciliar");
                              }}
                              type="button"
                              className="btn btn-sm btn-info"
                              name="set"
                            >
                              {" "}
                              <i className="fa fa-lock"></i>{" "}
                              {wWidth > 700 && "Conciliar Ventas"}
                            </button>
                            <button
                              disabled={this.state.tableData.rows.length === 0}
                              onClick={() => {
                                this.setAction("reintentar");
                              }}
                              className="btn btn-sm btn-warning"
                              name="set"
                            >
                              {" "}
                              <i className="fa fa-sync"></i>{" "}
                              {wWidth > 700 && "Reintentar"}
                            </button>
                          </>
                        )}

                        <ExcelFile
                          filename={"export-ordenes." + this.state.date_label}
                          element={
                            <button
                              disabled={this.state.tableData.rows.length == 0}
                              type="button"
                              className="btn btn-sm btn-success"
                              name="set"
                            >
                              {" "}
                              <i className="fa fa-file-excel"></i>{" "}
                              {wWidth > 700 && "Exportar Reporte"}
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.tableData.rows}
                            name="ordenes"
                          >
                            <ExcelColumn label="Id orden" value="id_orden" />
                            <ExcelColumn
                              label="Estado Pasarela de Pago"
                              value="estado"
                            />
                            <ExcelColumn
                              label="Estado SAP"
                              value="estado_sap"
                            />

                            <ExcelColumn
                              label="Estado Conciliaci\u00F3n"
                              value="estado_conciliacion"
                            />
                            <ExcelColumn
                              label="Fecha Cierre"
                              value="fecha_cierre"
                            />
                            <ExcelColumn
                              label="Fecha Conciliaci\u00F3n"
                              value="fecha_conciliacion"
                            />
                            <ExcelColumn label="Metodo Pago" value="metodo" />
                            <ExcelColumn
                              label="Id Transacción"
                              value="respuesta_pago"
                            />
                            <ExcelColumn
                              label="Fecha Solicitud Pago"
                              value="fecha_solicitud_pago"
                            />
                            <ExcelColumn
                              label="Fecha Registro Pago"
                              value="fecha_registro_pago"
                            />
                            <ExcelColumn
                              label="Doc. Identidad Cliente"
                              value="DocIdentidad"
                            />
                            <ExcelColumn
                              label="Email Cliente"
                              value="email_usuario"
                            />
                            <ExcelColumn
                              label="Codigo Oficina"
                              value="CodOficina"
                            />
                            <ExcelColumn label="Tipo" value="Tipo" />
                            <ExcelColumn
                              label="Razon Social"
                              value="RazonSocial"
                            />
                            <ExcelColumn label="Nit" value="Nit" />
                            <ExcelColumn
                              label="Codigo Servicio"
                              value="CodServicio"
                            />
                            <ExcelColumn
                              label="Servicio"
                              value="DescServicio"
                            />
                            <ExcelColumn
                              label="Monto Total"
                              value="MontoTotal"
                            />
                            <ExcelColumn
                              label="NumIdentificador"
                              value="NumIdentificador"
                            />
                            <ExcelColumn
                              label="Doc.Identidad Asesor"
                              value="DocIdentidadAsesor"
                            />
                            <ExcelColumn label="Canal" value="Canal" />
                          </ExcelSheet>

                          <ExcelSheet
                            data={this.state.tableData2.rows}
                            name="detalle"
                          >
                            <ExcelColumn
                              label="NRO. CERTIFICADO"
                              value="nro_certificado"
                            />
                            <ExcelColumn label="Id orden" value="id_orden" />
                            <ExcelColumn
                              label="NRO. CONTRATO (AGRUPADOR DE FAMILIA)"
                              value="nro_contrato"
                            />
                            <ExcelColumn label="PRODUCTO" value="producto" />
                            <ExcelColumn
                              label="PRIMA  COBRADA"
                              value="prima_cobrada"
                            />
                            <ExcelColumn
                              label="MONEDA  PRIMA"
                              value="moneda_prima"
                            />
                            <ExcelColumn
                              label="FECHA INICIO DE VIGENCIA"
                              value="fecha_inicio_de_vigencia"
                            />
                            <ExcelColumn
                              label="NRO. INTEGRANTE"
                              value="nro_integrante"
                            />
                            <ExcelColumn
                              label="TIPO DE DOCUMENTO"
                              value="tipo_de_documento"
                            />
                            <ExcelColumn
                              label="NUMERO DOCUMENTO"
                              value="numero_documento"
                            />
                            <ExcelColumn
                              label="COMPLEMENTO"
                              value="complemento"
                            />
                            <ExcelColumn
                              label="LUGAR DE EXPEDICION"
                              value="lugar_de_expedicion"
                            />
                            <ExcelColumn
                              label="AP. PATERNO"
                              value="ap_paterno"
                            />
                            <ExcelColumn
                              label="AP. MATERNO"
                              value="ap_materno"
                            />
                            <ExcelColumn label="NOMBRES" value="nombres" />
                            <ExcelColumn
                              label="FECHA DE NACIMIENTO"
                              value="fecha_de_nacimiento"
                            />
                            <ExcelColumn label="DIRECCION" value="direccion" />
                            <ExcelColumn
                              label="NACIONALIDAD"
                              value="nacionalidad"
                            />
                            <ExcelColumn label="GENERO" value="genero" />
                            <ExcelColumn
                              label="ESTADO CIVIL"
                              value="estado_civil"
                            />
                            <ExcelColumn label="OCUPACION" value="ocupacion" />
                            <ExcelColumn label="TELEFONO" value="telefono" />
                            <ExcelColumn label="Canal" value="canal" />
                          </ExcelSheet>
                        </ExcelFile>
                      </div>

                      <Table tableData={this.state.tableData} />

                      <Table tableData={this.state.tableData2} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Dashboard;
